// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$customer-portal-primary: mat.define-palette(mat.$indigo-palette);
$customer-portal-accent: mat.define-palette(
  mat.$pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$customer-portal-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$customer-portal-theme: mat.define-light-theme(
  (
    color: (
      primary: $customer-portal-primary,
      accent: $customer-portal-accent,
      warn: $customer-portal-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($customer-portal-theme);

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

@media print {
  // global style to hide elements from printing
  .no-print {
    display: none;
  }
}

.shepherd-element {
  @apply invisible z-[9999] w-full max-w-[350px] border-collapse rounded-[5px] border-4 opacity-0 shadow-[0_1px_4px_rgba(0,0,0,0.2)] transition-opacity delay-[0.3s] duration-[0.3s,visibility];
  outline: none;
}
.shepherd-enabled.shepherd-element {
  @apply visible bg-base-100 opacity-100;
}
.shepherd-modal-overlay-container {
  @apply pointer-events-none fixed left-0 top-0 z-[9997] h-0 w-screen overflow-hidden opacity-0;
  transition:
    all 0.3s ease-out,
    height 0ms 0.3s,
    opacity 0.3s 0ms;
}
.shepherd-theme-custom {
  @apply border-collapse border-2 border-primary text-center;
}
.shepherd-header {
  @apply border-collapse border-b-2 border-primary p-4;
}
.shepherd-text {
  @apply border-collapse border-b-2 border-primary p-4 text-center;
}
.shepherd-footer {
  @apply items-center justify-between space-x-6 rounded-bl-[5px] rounded-br-[5px] border-t-[black] border-t-[solid] p-4 px-3 pb-3 pt-2;
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  @apply h-screen opacity-50;
  transform: translateZ(0);
  transition:
    all 0.3s ease-out,
    height 0s 0s,
    opacity 0.3s 0s;
}
.shepherd-cancel-icon {
  @apply absolute right-5 top-0 cursor-pointer text-[2em] font-normal text-red-700 transition-[color] duration-[0.5s] ease-[ease] hover:text-red-600;
}
.shepherd-button {
  @apply w-20 cursor-pointer rounded-[3px] border-0 bg-primary text-center text-white transition-all duration-[0.5s] ease-[ease] hover:bg-primary;
}

.shepherd-arrow {
  @apply absolute z-[-1] h-4 w-4;
}

.shepherd-element[data-popper-placement^='top'] > .shepherd-arrow {
  @apply -bottom-2;
}
.shepherd-element[data-popper-placement^='bottom'] > .shepherd-arrow {
  @apply -top-2;
}
.shepherd-element[data-popper-placement^='left'] > .shepherd-arrow {
  @apply -right-2;
}
.shepherd-element[data-popper-placement^='right'] > .shepherd-arrow {
  @apply -left-2;
}
.shepherd-element.shepherd-centered > .shepherd-arrow {
  @apply opacity-0;
}
.shepherd-element.shepherd-has-title[data-popper-placement^='bottom']
  > .shepherd-arrow:before {
  @apply bg-[#e6e6e6];
}
